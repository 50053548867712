import classNames from "classnames";
import styles from "./Default.module.css";
import Footer from "./Footer";

const Impressum = () => (
  <>
    <section className={classNames(styles.content, styles.contentLeft)}>
      <h1 className={styles.title}>Impressum</h1>
      <p>
        <strong>
          Technische Umsetzung, Koordination, Redaktion, Graphic &amp; Design
        </strong>
      </p>
      <p className={styles.text}>HGV-Service Genossenschaft</p>
      <p className={styles.text}>Schlachthofstraße 59</p>
      <p className={styles.text}>I-39100 Bozen</p>
      <p className={styles.text}>Tel. +39 0471 317 700</p>
      <p className={styles.text}>Fax +39 0471 317 701</p>
      <p className={styles.text}>
        E-Mail: <a href="mailto:info@hgv.it">info@hgv.it</a>
      </p>
      <p className={styles.text}>
        Web:{" "}
        <a href="https://www.hgv.it/de/" target="_blank">
          www.hgv.it
        </a>
      </p>
      <p className={styles.text}>MwSt.Nr.: IT00576540215</p>
      <p className={styles.text}>Steuernr.: 00576540215 </p>
      <p>
        <strong>Bildernachweis</strong>
      </p>
      <p>Hoteliers- und Gastwirtejugend™, - ©</p>
      <p>
        <strong>Urheberrecht</strong>
      </p>
      <p>
        Die auf dieser Website veröffentlichten Texte, Bilder, Fotos und anderen
        Inhalte sind urheberrechtlich geschützt und dürfen ohne vorherige
        Zustimmung weder ganz noch teilweise vervielfältigt, weitergegeben,
        verbreitet oder gespeichert werden. © Hoteliers- und Gastwirtejugend -
        Alle Rechte vorbehalten
      </p>
      <p>
        <strong>Haftungsausschluss</strong>
      </p>
      <p>
        Die Inhalte dieser Website wurden mit größter Sorgfalt erstellt. Für die
        Richtigkeit, Vollständigkeit und Aktualität der Inhalte wird jedoch
        keine Haftung übernommen. Trotz sorgfältiger inhaltlicher Kontrolle wird
        keine Haftung für die Inhalte externer Links übernommen. Für den Inhalt
        der verlinkten Seiten sind ausschließlich deren Betreiber
        verantwortlich. Bei Kenntnis von Rechtsverstößen werden externe Links
        unverzüglich gelöscht.
      </p>
    </section>
    <Footer showPartner={false} />
  </>
);

export default Impressum;
