import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getMember } from "../http/memberAPI";
import { Member } from "../types/types";

const useMember = () => {
  const { memberId } = useParams();
  const [member, setMember] = useState<Member>();
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        memberId && setMember(await getMember(memberId));
      } catch (err) {
        setError(true);
      }
    };

    setTimeout(fetch, 1500);
  }, [memberId]);

  return { member, error };
};

export default useMember;
