import classNames from "classnames";
import styles from "./Default.module.css";
import Footer from "./Footer";

const Privacy = () => (
  <>
    <section className={classNames(styles.content, styles.contentLeft)}>
      <h1 className={styles.title}>Privacy</h1>
      <h3>
        ALLGEMEINE INFORMATIONEN BEI DER VERARBEITUNG VON PERSONENBEZOGENEN
        DATEN
      </h3>
      <p>
        Datenschutzerklärung im Sinne des Art. 13 der Verordnung (EU) 2016/679
        des Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz
        natürlicher Personen bei der Verarbeitung personenbezogener Daten, zum
        freien Datenverkehr und zur Aufhebung der Richtlinie 95/46/EG
        (Datenschutz-Grundverordnung)
      </p>
      <p>
        Gemäß EU-Datenschutzgrundverordnung (Verordnung 2016/679 des
        Europäischen Parlaments und des Rates) informieren wir Dich über das
        Folgende.
      </p>
      <h3>NAME UND KONTAKTDATEN DES VERANTWORTLICHEN</h3>
      <p>
        Verantwortlicher für die Verarbeitung der Daten ist Hoteliers- und
        Gastwirtejugend, Schlachthofstraße 59, I-39100 Bozen, E-Mail:{" "}
        <a href="mailto:info@hgj.it">info@hgj.it</a>, Tel:{" "}
        <a href="tel:0471 317999">0471 317999</a>.
      </p>
      <h3>PERSONENBEZOGENE DATEN UND BESONDERE KATEGORIEN VON DATEN</h3>
      <p>Personenbezogenen Daten sind insbesondere</p>
      <p className={styles.noMargin}>Vor- und Nachname,</p>
      <p className={styles.noMargin}>Informationen über Mitgliedschaft,</p>
      <p className={styles.noMargin}>Mitgliedsnummer,</p>
      <p>Zugriffs,- Nutzungs- und Kommunikationsdaten.</p>
      <h3>
        ZWECKE FÜR DIE VERARBEITUNG DER PERSONENBEZOGENEN DATEN UND
        RECHTSGRUNDLAGE FÜR DIE VERARBEITUNG
      </h3>
      <p>
        Die angegebenen Daten werden für folgende Zwecke verarbeitet:
        <ul>
          <li>
            Erbringung vorvertraglicher und vertraglicher Leistungen bezüglich
            der HGJ-Mitgliedschaft (z.B. Teilnahme an Veranstaltungen, Tagungen,
            Fachevents und Messen, Hotelbesichtigungen, Lehrfahrten und
            Workshops, Inanspruchnahme von Konventionen, Erfahrungs- und
            Informationsaustausch zwischen jungen Hoteliers und Gastwirten,
            usw.)
          </li>
          <li>Erfüllung gesetzlicher Pflichten</li>
          <li>
            Zugriffs, Nutzungs- und Kommunikationsdaten werden aus
            Sicherheitsgründen verarbeitet und um die Funktion der Internetseite
            zu gewähren.
          </li>
        </ul>
      </p>
      <p>Die Rechtsgrundlagen für die Verarbeitung Deiner Daten sind:</p>
      <ul>
        <li>
          die Erfüllung der vorvertraglichen und vertraglichen Maßnahmen und
          Verpflichtungen (Art. 6, Abs. 1, lit. b DSGVO);{" "}
        </li>
        <li>
          gesetzliche oder rechtliche Verpflichtungen (Art. 6, Abs. 1, lit. c
          DSGVO);
        </li>
        <li>
          die von Dir eingeholte Einwilligung (Art. 6, Abs. 1, lit. a DSGVO);
        </li>
        <li>
          die Wahrung berechtigter Interessen (Art. 6, Abs. 1, lit. f DSGVO);
        </li>
      </ul>
      <h3>ZUGRIFFS,- NUTZUNGS- UND KOMMUNIKATIONSDATEN</h3>
      <p>
        <strong>Server-Log-Dateien</strong>
      </p>
      <p>
        Vom Provider der Internetseite werden Daten automatisch erhoben und in
        Server-Log-Dateien für die Dauer von 2 Jahren gespeichert. Es handelt
        sich dabei um folgende Daten: Anonymisierte IP-Adresse, Browser und
        Browser-Version, Zugriffszeit, Aufenthaltsdauer.
      </p>
      <p>
        <strong>SSL-Verschlüsselung</strong>
      </p>
      <p>
        Um die Sicherheit Deiner Daten bei der Übertragung zu gewähren,
        verwenden wir dem aktuellen Stand der Technik entsprechende
        Verschlüsselungsverfahren über https. Die Speicherung und Verarbeitung
        der Zugriffs-, Nutzungs- und Kommunikationsdaten erfolgt aus
        Sicherheitsgründen und um die Funktion der Internetseite zu
        gewährleisten. Die rechtliche Grundlage dafür ist das berechtige
        Interesse im Sinne des Art. 6, Abs. 1, lit. f DSGVO.
      </p>
      <p>
        <strong>Zweck und rechtliche Grundlage</strong>
      </p>
      <p>
        Die Speicherung und Verarbeitung der Zugriffs-, Nutzungs- und
        Kommunikationsdaten erfolgt aus Sicherheitsgründen und um die Funktion
        der Internetseite zu gewährleisten. Die rechtliche Grundlage dafür ist
        das berechtige Interesse im Sinne des Art. 6, Abs. 1, lit. f DSGVO.
      </p>
      <h3>EMPFÄNGER DER PERSONENBEZOGENEN DATEN</h3>
      <p>
        Ihre personenbezogenen Daten werden prinzipiell nicht weitergegeben. In
        der Regel werden die personenbezogenen Daten innerhalb des Hoteliers-
        und Gastwirtejugend (HGJ) unter Berücksichtigung der zulässigen
        Verwendungszwecke verarbeitet. Bei Bedarf werden die personenbezogene
        Daten an Auftragsverarbeiter, sowie an Dritte, die für die Erbringung
        der von Ihnen gewünschten Dienstleistungen beigezogen werden müssen oder
        für Fachdienstleistungen in Anspruch genommen werden, übermittelt.
      </p>
      <h3>RECHTE DES BETROFFENEN</h3>
      <p>
        <strong>Recht auf Auskunft</strong>
      </p>
      <p>
        Sie sind gemäß Art. 15 DSGVO berechtigt Auskunft zu erhalten, welche
        personenbezogenen Daten verarbeitet werden, woher diese stammen, um
        welche Daten es sich handelt, ob diese weitergegeben werden und an wen,
        und was mit den Daten gemacht wird.
      </p>
      <p>
        <strong>Recht auf Berichtigung und Löschung</strong>
      </p>
      <p>
        Gemäß Art. 16 DSGVO können Sie jederzeit die Berichtigung oder
        Vervollständigung der personenbezogenen Daten verlangen. Ebenso haben
        Sie im Sinne des Art. 17 DSGVO das Recht auf Löschung. Insbesondere bei
        Daten, welche veröffentlicht wurden, haben Sie auch das „Recht auf
        Vergessenwerden“, d.h. es werden die notwendigen Schritte unternommen,
        um alle Links, Kopien, Replikationen oder Ähnliches zu löschen.{" "}
      </p>
      <p>
        <strong>Widerspruchsrecht</strong>
      </p>
      <p>
        Sie können jederzeit von Ihrem Widerspruchsrecht gemäß Art. 21 DSGVO
        Gebrauch machen und die erteilte Einwilligungserklärung mit Wirkung für
        die Zukunft abändern oder gänzlich widerrufen.
      </p>
      <p>
        <strong>Recht auf Einschränkung (oder auch Sperrung)</strong>
      </p>
      <p>
        Sie können im Sinne des Art. 18 DSGVO auch die Einschränkung der
        Verarbeitung der personenbezogenen Daten verlangen. Die erhobenen Daten
        dürfen dann nur mit einer individuellen Einwilligung verarbeitet werden
        oder die Verarbeitung muss dann konkret eingeschränkt werden.
      </p>
      <p>
        <strong>Recht auf Datenübertragbarkeit</strong>
      </p>
      <p>
        Gemäß Art. 20 DSGVO haben Sie das Recht, die bereitgestellten
        personenbezogenen Daten in einem strukturierten, gängigen,
        maschinenlesbaren Format übermittelt zu erhalten und die Übertragung
        Ihrer personenbezogenen Daten auf einen anderen Verantwortlichen zu
        verlangen. Auch ein Recht auf Beschwerde bei der staatlichen
        Aufsichtsbehörde ist gemäß Art. 77 DSGVO vorgesehen.
      </p>
    </section>
    <Footer showPartner={false} />
  </>
);

export default Privacy;
