import classNames from "classnames";
import CheckError from "../assets/svg/check_error.svg?react";
import styles from "./Default.module.css";
import Footer from "./Footer";
import Icon from "./Icon";

const Default = () => (
  <>
    <section className={classNames(styles.content, styles.contentPadding)}>
      <h1 className={styles.title}>Ups, etwas ist schiefgelaufen!</h1>
      <Icon glyph={CheckError} className={styles.watermark} />
    </section>
    <Footer showPartner={false} />
  </>
);

export default Default;
