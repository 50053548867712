import classNames from "classnames";
import CheckInvalid from "../assets/svg/check_invalid.svg?react";
import styles from "./Default.module.css";
import Footer from "./Footer";
import Icon from "./Icon";

const MemberInvalid = () => (
  <>
    <section className={classNames(styles.content, styles.contentPadding)}>
      <h1 className={styles.title}>Diese Mitgliedskarte ist ungültig!</h1>
      <Icon glyph={CheckInvalid} className={styles.watermark} />
    </section>
    <Footer />
  </>
);

export default MemberInvalid;
