import styles from "./Loading.module.css";

const Loading = () => (
  <div className={styles.loading}>
    <div className={styles.loader}>
      <div className={styles.fills}>
        <div className={styles.fillMask1}>
          <div className={styles.fillSubMask1}>
            <div className={styles.fill} />
          </div>
        </div>
        <div className={styles.fillMask2}>
          <div className={styles.fillSubMask2}>
            <div className={styles.fill} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Loading;
