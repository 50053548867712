import classNames from "classnames";
import CheckValid from "../assets/svg/check_valid.svg?react";
import HgjFriend from "../assets/svg/hgj_friend.svg?react";
import HgjMember from "../assets/svg/hgj_member.svg?react";
import useMember from "../hooks/useMember";
import { Member as MemberType } from "../types/types";
import Default from "./Default";
import styles from "./Default.module.css";
import Footer from "./Footer";
import Icon from "./Icon";
import Loading from "./Loading";
import MemberInvalid from "./MemberInvalid";

const getTypes = (member: MemberType | undefined) => {
  const isMember =
    member?.type === "ordinary" || member?.type === "extraordinary";
  const isNewsType =
    member?.type === "free-newspaper" ||
    member?.type === "newspaper-subscription";
  const isCanceled = member?.status === "canceled";

  return { isMember, isNewsType, isCanceled };
};

const Member = () => {
  const { member, error } = useMember();
  const { isMember, isNewsType, isCanceled } = getTypes(member);

  if (error || isNewsType) return <Default />;
  if (isCanceled) return <MemberInvalid />;

  return !member ? (
    <Loading />
  ) : (
    <>
      <section className={styles.content}>
        <Icon
          glyph={isMember ? HgjMember : HgjFriend}
          className={styles.logoIcon}
        />
        <div className={styles.item}>
          <p className={styles.label}>Name</p>
          <h1>
            {member.firstName} {member.lastName}
          </h1>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Mitgliedsnummer</p>
          <h2>{member.id}</h2>
          <Icon
            glyph={CheckValid}
            className={classNames(styles.watermark, styles.valid)}
          />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Member;
