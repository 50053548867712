import { BrowserRouter } from "react-router-dom";
import styles from "./App.module.css";
import AppRouter from "./AppRouter";

const App = () => (
  <div className={styles.app}>
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  </div>
);

export default App;
