import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router";
import Default from "./Default";
import Impressum from "./Impressum";
import Member from "./Member";
import Privacy from "./Privacy";

const AppRouter = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path="/cards/:memberId" element={<Member />} />
      <Route path="/impressum" element={<Impressum />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="*" element={<Default />} />
    </Routes>
  );
};

export default AppRouter;
